<template>
  <div class="serviceSheetList-wrapper">
    <!-- exportPermission="export" -->
    <!-- :exportUrl="exportUrl" -->
    <!-- :extraParams="extraParams" -->
      <!-- :isMultiSelect="true" -->
    <list ref="list"
          :searchUrl="searchUrl"
          :searchParams.sync="searchParams"
          :extraParams="extraParams"
          exportPermission="export"
          :exportUrl="exportUrl"
          :headers="headers"
          :limitExport="limitExport"
          @exportClick="exportClick">
      <template #headerSlot>
        <!-- <v-button text="新增"
                  permission="add"
                  @click="create"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input label="工单编号" v-model="searchParams.serialNumber"></v-input>
        <v-select label="工单状态" v-model="searchParams.status" :options="orderStatusOps" @change="changeStatus"></v-select>
        <v-input label="业主姓名" v-model="searchParams.userName" placeholder="请输入完整姓名"></v-input>
        <v-input label="业主手机" v-model="searchParams.userMobile" placeholder="请输入完整手机号"></v-input>
        <v-input label="业主地址" v-model="searchParams.createUserAddress" placeholder="请输入业主地址"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-datepicker label="提交时间段" format="YYYY-MM-DD HH:mm:ss" type="rangedatetimer" :startTime.sync="searchParams.createStartTime" :endTime.sync="searchParams.createEndTime"></v-datepicker>
        <v-select label="收费状态" v-model="searchParams.isFee" :options='isFeeOps'></v-select>
        <v-select label="支付状态" v-model="searchParams.isPay" :options='isPayOps'></v-select>
      </template>
      <template #operateSlot="scope">
        <div>
          <v-button v-if="detailView"
                    :text="editText"
                    type="text"
                    @click="edit(scope.row)"></v-button>
          <v-button v-if="detailView &&scope.row.status!=7 && scope.row.status!=8"
                    text="编辑"
                    type="text"
                    @click="edit(scope.row,isEdit=true)"></v-button>
          <!-- <v-button v-if="scope.row.status === 5"
                    permission="feedback"
                    text="添加处理中反馈"
                    type="text"
                    @click="showFeedback(scope.row)"></v-button> -->
        </div>
      </template>
      <template #batchSlot="scope">
        <!-- <v-button text="批量接单"
                  permission="orderDeal"
                  :disabled="scope.selectedData.ids.length === 0"
                  @click="batch(scope.selectedData, 6)"></v-button>
        <v-button text="批量处理"
                  permission="orderDeal"
                  :disabled="scope.selectedData.ids.length === 0"
                  @click="batch(scope.selectedData, 2)"></v-button>
        <v-button text="批量完成"
                  permission="orderDeal"
                  :disabled="scope.selectedData.ids.length === 0"
                  @click="batch(scope.selectedData, 3)"></v-button> -->
      </template>
    </list>
    <el-dialog title="工单进度"
               :visible.sync="dialogProgress"
               width="500px"
               @close="dialogProgressClose">
      <div>
        <div class="progress-textarea">
          <v-textarea v-model="progressForm.remark"
                      placeholder="请输入处理反馈"
                      :width="460"></v-textarea>
        </div>
        <div class="progress-upload">
          <v-uploader ref="myUpload"
                      :action="uploadUrl"
                      :imgUrls.sync="progressForm.file"
                      :limit="4"
                      :fileWidth="60">
          </v-uploader>
          <div class="right">
            <v-button type="success"
                      @click="submitProgress">提 交</v-button>
          </div>
        </div>
        <el-timeline>
          <el-timeline-item class="pro-group"
                            v-for="(item, index) in progressDetail"
                            :key="index">
            <div class="progress">
              <div class="progress-top">
                <span class="left">{{item.track}}</span>
                <span class="right">{{item.time}}</span>
              </div>
              <div class="progress-remark">{{item.remark}}</div>
              <div class="progress-view"
                   v-if="item.images.length > 0">
                <v-preview :imgs="item.images"
                           :imgStyle="previewStyle"></v-preview>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  batchURL,
  getUserRoomsURL,
  getViewSubURL,
  getAdminsURL,
  getPayTypeListURL,
  queryTaskListURL,
  uploadURL,
  addFeedbackInfoURL,
  getOrderLabelNoPageListURL,
  querySignTypeURL,
  queryServiceIDURL,
  getServiceCategoryURL
} from './api'
import {
  orderStatusOps,
  orderStatusMap,
  isPayOps,
  isPayMap,
  payCategoryOps,
  payCategoryMap,
  firstDealOps,
  isAfterSalesIngOps,
  isReturnVisitOps,
  isSignTypeOps,
  isFeeOps,
  isFeeMap
} from './map'
import { generateMapByOpts } from 'common/utils'
import { vPreview, vUploader } from 'components/control'
import { Timeline, TimelineItem } from 'element-ui'
import { createAlinkVNode } from 'common/vdom'
import { orgParams, communityParams, regionParams } from 'common/select2Params'
import moment from 'moment'
import { mapMutations } from 'vuex'

export default {
  name: 'ServiceSheetList',
  components: {
    vPreview,
    vUploader,
    elTimeline: Timeline,
    elTimelineItem: TimelineItem
  },
  data () {
    let _this = this
    return {
      isFeeMap,
      isFeeOps,
      orderStatusOps,
      isPayOps,
      payCategoryOps,
      firstDealOps,
      sourceTypeOps: [],
      sourceTypeMap: {},
      isAfterSalesIngOps,
      isReturnVisitOps,
      isSignTypeOps,
      orgParams,
      communityParams,
      regionParams,
      uploadUrl: uploadURL,
      payTypeOps: [],
      payTypeMap: {},
      orderNumberOps: [],
      countersignTypeOps: [],
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: [
        {
          text: '全部',
          value: undefined
        }, {
          text: '开启',
          value: 1
        }, {
          text: '关闭',
          value: 0
        }
      ],
      houseParams: {
        searchUrl: getUserRoomsURL,
        request: {
          text: 'roomNum',
          value: 'houseId'
        }
      },
      subParams: {
        searchUrl: getViewSubURL,
        request: {
          text: 'subName',
          value: 'subId'
        }
      },
      adminParams: {
        searchUrl: getAdminsURL,
        request: {
          text: 'userName',
          value: 'adminId'
        }
      },
      searchParams: {
        serialNumber: '',
        status: undefined,
        userName: '',
        userMobile: '',
        type: 2,// 服务工单
        createUserAddress: '',
        communityId: '',
        createStartTime: '',
        createEndTime: '',
        orderStatusList: '',
        // houseId: '',
        // isPay: undefined,
        // payCategory: undefined,
        // orgId: '',
        // caseType: undefined,
        // subId: '',
        // orderNumber: undefined,
        isFee:undefined,
        isPay: undefined,
        // payStartTime: '',
        // payEndTime: '',
        // sourceType: undefined,
        // communityId: '',
        // orderStartTime: '',
        // orderEndTime: '',
        // referStartTime: '',
        // referEndTime: '',
        // regionId: '',
        // isAfterSalesIng: undefined,
        // isReturnVisit: undefined,
        // adminId: '',
        // isSignType: undefined,
        // countersignType: undefined,
        // subCode: ''
      },
      extraParams: {
        // startDealTime: '',
        // endDealTime: '',
      },
      headers: [
        {
          prop: 'serialNumberNode',
          label: '工单编号',
          formatter (row) {
            let h = _this.$createElement
            return h('span', {
              domProps: {
                innerText: row.serialNumber
              },
              style: {
                color: '#1b8cf2',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  _this.edit(row)
                }
              }
            })
          }
        },
        {
          prop: 'userName',
          label: '用户姓名'
        },
        {
          prop: 'categoryName',
          label: '工单类别'
        },
        {
          prop: 'userMobile',
          label: '用户手机'
        },
        {
          prop: 'taskInfoAddress',
          label: '业主地址'
        },
        {
          prop: 'createTime',
          label: '提交时间'
        },
        {
          prop: 'communityName',
          label: '园区名称'
        },
        // {
        //   prop: 'subName',
        //   label: '所属服务'
        // },
        {
          prop: 'status',
          label: '状态',
          formatter (row) {
            return orderStatusMap[row.status]
          }
        },
        {
          prop: 'feeType',
          label: '收费状态',
          formatter (row) {
            return isFeeMap[row.isFee]
          }
        },
        {
          prop: 'payType',
          label: '支付状态',
          formatter (row) {
            if(!row.isFee){
              return '/'
            }
            return isPayMap[row.isPay]
          }
        },
        // {
        //   prop: 'totalMoney',
        //   label: '工单金额',
        //   align: 'right'
        // },
        // {
        //   prop: 'payTypeText',
        //   label: '支付方式',
        //   formatter (row) {
        //     return _this.payTypeMap[row.payType]
        //   }
        // },
        // {
        //   prop: 'isPayText',
        //   label: '支付状态',
          // formatter (row) {
          //   let h = _this.$createElement
          //   return h('span', {
          //     domProps: {
          //       innerHTML: `${isPayMap[row.isPay]}<br /> ${row.onlinePaidTime}`
          //     }
          //   })
          // }
        // },
        // {
        //   prop: 'orgName',
        //   label: '所属组织'
        // },
        // {
        //   prop: 'payCategoryText',
        //   label: '工单类型',
        //   formatter (row) {
        //     return payCategoryMap[row.payCategory]
        //   }
        // },
        // {
        //   prop: 'sourceTypeText',
        //   label: '工单来源',
        //   formatter: (row, prop) => {
        //     if (row.sourceType === 1 || row.sourceType === 2) {
        //       let opts = {
        //         cb: _this.showUser,
        //         text: _this.sourceTypeMap[row.sourceType]
        //       }
        //       return createAlinkVNode(this, row, prop, opts)
        //     } else {
        //       return _this.sourceTypeMap[row.sourceType]
        //     }
        //   }
        // },
        // {
        //   prop: 'regionName',
        //   label: '所属公司'
        // }
      ],
      batchText: '您的工单已接收，稍后将安排工作人员为您服务。',
      postHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      },
      previewStyle: {
        width: '60px',
        height: '60px',
        marginRight: '10px'
      },
      selectCaseId: '',
      dialogProgress: false,
      progressDetail: [],
      progressForm: {
        remark: '',
        file: []
      }
    }
  },
  async created () {
    const { orgId, communityId, orderStatus } = this.$route.query
    if (orgId) {
      this.searchParams.orgId = orgId
    }
    if (communityId) {
      this.searchParams.communityId = communityId
    }
    if (orderStatus) {
      this.searchParams.status = Number(orderStatus)
    }
    this.searchParams.subCode= this.$store.state.userInfo.regionType == '3'? '9300' : ''
    // await this.$store.dispatch('mapStore/queryPayType')
    // const { setPayTypeOps, payTypeMap } = this.$store.getters['mapStore/getPayType']()
    // this.payTypeOps = setPayTypeOps(1)
    // this.payTypeMap = payTypeMap

    // 处理跳转过来带的参数
    this.dealWithRouteParams()
    await this.$store.dispatch('mapStore/queryOrderSource')
    const { setOrderSourceOps, orderSourceMap } = this.$store.getters['mapStore/getOrderSource']()
    this.sourceTypeOps = setOrderSourceOps(1)
    this.sourceTypeMap = orderSourceMap
    this.querySignType()


    //分页器当前页保存
    let curPage = localStorage.getItem('curPage')
    if(curPage){
      this.$refs.list.$refs.paginationPanel.params.curPage = Number(curPage)
    }
    localStorage.removeItem('curPage')
  },
  computed: {
    detailView () { return this.$store.getters.getPermission('detailView') },
    editText () {
      return this.detailView && !this.orderDeal ? '查看' : '查看'
    },
    subExtraParams () {
      return {
        subCode: this.$store.state.userInfo.regionType == '3'? '9300' : undefined
      }
    },
    limitExport() {
      // if(!this.searchParams.communityId) {
      //   return true
      // }
      // 先不限制
      return false
    }
  },
  mounted () {
    this.getPayTypeOps()
    if (this.searchParams.isSignType) {
      this.isSignTypeChange(this.searchParams.isSignType)
    }
    // 处理跳转过来带的参数
    // this.dealWithRouteParams()
  },
  methods: {
    // 处理跳转过来的数据
    dealWithRouteParams () {
      const { fromPage } = this.$route.query
      if (fromPage === undefined) {
        return false
      }
      //  这个处理是防止跳转带的参数，被之前本地缓存参数污染
      this.searchParams = {
        serialNumber: '',
        status: undefined,
        userName: '',
        userMobile: '',
        taskInfoAddress: '',
        communityId: '',
        createStartTime: '',
        createEndTime: '',
        orderStatusList: ''
        // houseId: '',
        // isPay: undefined,
        // payCategory: undefined,
        // orgId: '',
        // caseType: undefined,
        // subId: '',
        // orderNumber: undefined,
        // payType: undefined,
        // payStartTime: '',
        // payEndTime: '',
        // sourceType: undefined,
        // communityId: '',
        // orderStartTime: '',
        // orderEndTime: '',
        // referStartTime: '',
        // referEndTime: '',
        // regionId: '',
        // isAfterSalesIng: undefined,
        // isReturnVisit: undefined,
        // adminId: '',
        // isSignType: undefined,
        // countersignType: undefined
      }

      const query = this.$route.query

      // 工单状态
      if (query.orderStatus !== undefined) {
        this.searchParams.status = parseInt(query.orderStatus)
      }
      // //  提交时间段： 今日往前90天~今日
      // if (query.referStartTime !== undefined) {
      //   this.searchParams.referStartTime = query.referStartTime
      // }
      // //  提交时间段： 今日往前90天~今日
      // if (query.referEndTime !== undefined) {
      //   this.searchParams.referEndTime = query.referEndTime
      // }
      //  提交时间段： 今日往前90天~今日
      if (query.referStartTime !== undefined) {
        this.searchParams.createStartTime = query.referStartTime
      }
      //  提交时间段： 今日往前90天~今日
      if (query.referEndTime !== undefined) {
        this.searchParams.createEndTime = query.referEndTime
      }
      //  是否回访
      if (query.isReturnVisit !== undefined) {
        this.searchParams.isReturnVisit = parseInt(query.isReturnVisit)
      }
      //  优先处理
      if (query.caseType !== undefined) {
        this.searchParams.caseType = parseInt(query.caseType)
      }

      if (query.startDealTime !== undefined) {
        this.extraParams.startDealTime = query.startDealTime
      }

      if (query.endDealTime !== undefined) {
        this.extraParams.endDealTime = query.endDealTime
      }

      if (query.orderStatusList !== undefined) {
        this.searchParams.orderStatusList = query.orderStatusList
      }
      // 修改vux存储的值
      this.setListSearch({
        name: 'orderScheduling.ServiceWorkOrder',
        params: {
          ...this.searchParams
        }
      })
    },
    getPayTypeOps () {
      this.$axios.get(getPayTypeListURL).then(res => {
        if (res.status === 100) {
          const { data } = res
          let payTypeOps = data.map(item => {
            return {
              text: item.payTypeDesc,
              value: item.payType
            }
          })
          this.payTypeOps = [
            {
              text: '全部',
              value: undefined
            },
            ...payTypeOps
          ]
          this.payTypeMap = generateMapByOpts(payTypeOps)
        }
      })
    },
    updateStatus (ids, status) {
      let _this = this
      let _ids = ids.join(',')
      let postData = {
        orderIds: _ids,
        orderStatus: status,
        taskMemo: this.batchText
      }
      if (status === 3) {
        postData.operationDes = this.batchText
        postData.operaType = 'done'
      }
      _this.$axios.post(batchURL, this.$qs.stringify(postData), this.postHeader).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    batch (selected, status) {
      let ids = selected.ids
      const h = this.$createElement
      if (status === 6) {
        this.batchText = '您的工单已接收，稍后将安排工作人员为您服务。'
      } else if (status === 2) {
        this.batchText = '您的工单已开始处理，请关注。'
      } else if (status === 3) {
        this.batchText = '服务已完毕，请为本次服务打分/评价，祝生活幸福!'
      }
      this.$confirm('', {
        title: '反馈信息',
        type: 'aa',
        message: h('v-textarea', {
          props: {
            value: this.batchText,
            width: 395
          },
          style: {
            marginBottom: '20px'
          },
          on: {
            input: (event) => {
              this.batchText = event
              this.$emit('input', event)
            }
          }
        })
      }).then((isOk) => {
        isOk && this.updateStatus(ids, status)
      })
    },
    create () {
      this.$router.push({
        name: 'orderSchedulingForm'
      })
    },
    edit (row,isEdit) {
      this.$router.push({
        name: 'orderSchedulingForm',
        query: {
          id: row.id,
          isEdit
          // orderCode: row.serialNumber,
          // communityId: row.communityId
        }
      })

      //分页器当前页保存
      let curPage =  this.$refs.list.$refs.paginationPanel.params.curPage
      localStorage.setItem('curPage',curPage)
    },
    showUser (row) {
      const h = this.$createElement
      this.$confirm('', {
        title: '提交人信息',
        type: 'infos',
        message: h('div', {
          style: {
            textAlign: 'center',
            lineHeight: '30px'
          }
        }, [
          h('div', {
            domProps: {
              innerText: `姓名：${row.inuserName}`
            }
          }),
          h('div', {
            domProps: {
              innerText: `手机：${row.inuserMobileNum}`
            }
          })
        ]),
        showClose: true,
        showCancelButton: false,
        showConfirmButton: false,
        center: true
      })
    },
    showFeedback (row) {
      this.selectCaseId = row.id
      this.$axios.get(queryTaskListURL, { params: {
        caseId: row.id
      } }).then(res => {
        if (res.status === 100) {
          this.progressDetail = res.data ? res.data : []
          this.dialogProgress = true
        }
      })
    },
    dialogProgressClose () {
      this.progressForm.remark = ''
      this.progressForm.file = []
    },
    submitProgress () {
      if (!this.progressForm.remark) {
        this.$message({
          type: 'error',
          message: '请添加工单处理过程中的阶段性成果',
          center: true
        })
        return false
      }
      let data = {
        file: JSON.stringify(this.progressForm.file.map(url => { return { url } })),
        remark: this.progressForm.remark,
        caseId: this.selectCaseId,
        taskStatus: 2
      }
      this.$axios.post(addFeedbackInfoURL, data, this.postHeader).then(res => {
        if (res.status === '100') {
          this.dialogProgress = false
          this.$message({
            type: 'success',
            message: '反馈提交成功',
            center: true
          })
        }
      })
    },
    isSignTypeChange (val) {
      if (val) {
        this.$axios.get(`${getOrderLabelNoPageListURL}?categoryId=${val}`).then(res => {
          if (res.status === 100) {
            let data = res.data
            this.countersignTypeOps = [
              {
                text: '全部',
                value: undefined
              },
              ...data.map(item => {
                return {
                  text: item.labelName,
                  value: item.id
                }
              })
            ]
            this.searchParams.countersignType = undefined
          }
        })
      } else {
        this.countersignTypeOps = [
          {
            text: '全部',
            value: undefined
          }
        ]
      }
    },
    subChange (val, isBack) {
      // if (val && val.name) {
      //   let subOpsType = subBackMap[val.name]
      //   if (subOpsType !== undefined) {
      //     this.orderNumberOps = orderNumberOps[subOpsType]
      //   } else {
      //     this.orderNumberOps = []
      //   }
      // }
      if (!isBack) {
        this.searchParams.orderNumber = undefined
      }
      if (val && val.subCode) {
        this.getServiceCategory(val.subCode)
      } else {
        this.orderNumberOps = []
      }
    },
    async querySignType () {
      let res = await this.$axios.get(queryServiceIDURL)
      let { data } = await this.$axios.get(querySignTypeURL, { params: { parentId: res.data } })
      this.isSignTypeOps = data.map(item => ({ text: item.categoryName, value: item.id }))
      this.isSignTypeOps.unshift({
        text: '全部',
        value: undefined
      })
    },

    async getServiceCategory (subCode) {
      let params = {
        subCode: subCode
      }
      const { data, status } = await this.$axios.get(getServiceCategoryURL, { params })
      let list = []
      if (status === 100) {
        list = data || []
        list = list.map(item => ({ text: item.name, value: item.id }))
      }
      if (list.length > 0) {
        list.unshift({
          text: '全部',
          value: undefined
        })
      }
      this.orderNumberOps = list
    },
    changeStatus() {
      console.log(123455)
      this.searchParams.orderStatusList = ''
    },
    exportClick() {
      // 先不限制
      // if(!this.searchParams.communityId) {
      //   this.$notify({
      //     title: '警告',
      //     message: '请选择所属项目',
      //     type: 'warning'
      //   });
      // }
    },
    ...mapMutations('searchStore', [
      'setListSearch'
    ])
  }
}
</script>
<style lang="scss" scoped>
.progress {
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-left: -23px;
  padding-left: 23px;
  .progress-top {
    display: flex;
    justify-content: space-between;
    line-height: 28px;
    border-bottom: 1px solid #ccc;

    .left {
      color: #1b8cf2;
    }
    .right {
    }
  }
  .progress-remark {
    margin: 15px 0;
  }
  .progress-view {
  }
}
.pro-group:last-child {
  .progress {
    border: none;
  }
}
.rates {
  text-align: left;
  margin-top: 10px;
}
.progress-textarea {
  margin-bottom: 20px;
}
.progress-upload {
  text-align: left;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .right {
    padding-top: 15px;
  }
}
</style>
