var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "serviceSheetList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          extraParams: _vm.extraParams,
          exportPermission: "export",
          exportUrl: _vm.exportUrl,
          headers: _vm.headers,
          limitExport: _vm.limitExport,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
          exportClick: _vm.exportClick,
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "工单编号" },
                  model: {
                    value: _vm.searchParams.serialNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "serialNumber", $$v)
                    },
                    expression: "searchParams.serialNumber",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "工单状态", options: _vm.orderStatusOps },
                  on: { change: _vm.changeStatus },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "业主姓名", placeholder: "请输入完整姓名" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "业主手机", placeholder: "请输入完整手机号" },
                  model: {
                    value: _vm.searchParams.userMobile,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userMobile", $$v)
                    },
                    expression: "searchParams.userMobile",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "业主地址", placeholder: "请输入业主地址" },
                  model: {
                    value: _vm.searchParams.createUserAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "createUserAddress", $$v)
                    },
                    expression: "searchParams.createUserAddress",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c("v-datepicker", {
                  attrs: {
                    label: "提交时间段",
                    format: "YYYY-MM-DD HH:mm:ss",
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.createStartTime,
                    endTime: _vm.searchParams.createEndTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "createStartTime",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "createStartTime",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "createEndTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "createEndTime", $event)
                    },
                  },
                }),
                _c("v-select", {
                  attrs: { label: "收费状态", options: _vm.isFeeOps },
                  model: {
                    value: _vm.searchParams.isFee,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isFee", $$v)
                    },
                    expression: "searchParams.isFee",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "支付状态", options: _vm.isPayOps },
                  model: {
                    value: _vm.searchParams.isPay,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isPay", $$v)
                    },
                    expression: "searchParams.isPay",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  [
                    _vm.detailView
                      ? _c("v-button", {
                          attrs: { text: _vm.editText, type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.edit(scope.row)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.detailView &&
                    scope.row.status != 7 &&
                    scope.row.status != 8
                      ? _c("v-button", {
                          attrs: { text: "编辑", type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.edit(scope.row, (_vm.isEdit = true))
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return undefined
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "工单进度",
            visible: _vm.dialogProgress,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogProgress = $event
            },
            close: _vm.dialogProgressClose,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "progress-textarea" },
                [
                  _c("v-textarea", {
                    attrs: { placeholder: "请输入处理反馈", width: 460 },
                    model: {
                      value: _vm.progressForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.progressForm, "remark", $$v)
                      },
                      expression: "progressForm.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "progress-upload" },
                [
                  _c("v-uploader", {
                    ref: "myUpload",
                    attrs: {
                      action: _vm.uploadUrl,
                      imgUrls: _vm.progressForm.file,
                      limit: 4,
                      fileWidth: 60,
                    },
                    on: {
                      "update:imgUrls": function ($event) {
                        return _vm.$set(_vm.progressForm, "file", $event)
                      },
                      "update:img-urls": function ($event) {
                        return _vm.$set(_vm.progressForm, "file", $event)
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c(
                        "v-button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.submitProgress },
                        },
                        [_vm._v("提 交")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-timeline",
                _vm._l(_vm.progressDetail, function (item, index) {
                  return _c(
                    "el-timeline-item",
                    { key: index, staticClass: "pro-group" },
                    [
                      _c("div", { staticClass: "progress" }, [
                        _c("div", { staticClass: "progress-top" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(_vm._s(item.track)),
                          ]),
                          _c("span", { staticClass: "right" }, [
                            _vm._v(_vm._s(item.time)),
                          ]),
                        ]),
                        _c("div", { staticClass: "progress-remark" }, [
                          _vm._v(_vm._s(item.remark)),
                        ]),
                        item.images.length > 0
                          ? _c(
                              "div",
                              { staticClass: "progress-view" },
                              [
                                _c("v-preview", {
                                  attrs: {
                                    imgs: item.images,
                                    imgStyle: _vm.previewStyle,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }